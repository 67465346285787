#menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#menu .menu-img {
    width: 100%;
    margin: 0px 30px;
}

@media (max-width: 800px) { 
    #menu .menu-img {
        width: 100%;
        margin: 0px 0px;
    }
}