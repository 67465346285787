#location {
    background-color: rgb(200, 224, 178);
    margin: 50px 0px;
}

#location-section {
    padding-left: 130px;
}

#location-box {
    border: 1px solid black;
    padding: 5px;
    display: inline-block;
}

#location-box:hover {
    background-color: rgba(179, 202, 158, 0.824);
}

#location-image-container {
    width: 100%;
    background-image:linear-gradient(to right, #C9E1B4, rgba(255, 255, 255, 0)), url(../img/location-img.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 600px) { 
    #location-section {
        padding-left: 0px;
    }
    #location-box {
        color: white;
    }
    #location-box:hover {
        background-color: black;
    }
    #location {
        background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(../img/location-img.jpeg);
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
    }

    #location-image-container {
        display: none;
    }
}